// import React from "react";
// import { BarStack } from "@visx/shape";
// import { Group } from "@visx/group";
// import { AxisBottom, AxisLeft } from "@visx/axis";
// import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
// import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
// import { localPoint } from "@visx/event";
// import { extent, max } from "d3-array";
// import { convertToK } from "../../utils/convertToK";

// // Tooltip styles
// const defaultMargin = { top: 40, right: 0, bottom: 0, left: 40 };
// const tooltipStyles = {
//   ...defaultStyles,
//   minWidth: 100,
//   backgroundColor: "white",
//   color: "black",
// };

// // Accessor
// const getQuarter = (d) => d.quarter;

// let tooltipTimeout;

// export default function StackedBarChart({
//   width,
//   height,
//   data = [],
//   events = false,
//   margin = defaultMargin,
//   colors,
//   leftLabel = "Score",
//   colorDomain = [],
// }) {
//   const {
//     tooltipOpen,
//     tooltipLeft,
//     tooltipTop,
//     tooltipData,
//     hideTooltip,
//     showTooltip,
//   } = useTooltip();

//   const { containerRef, TooltipInPortal } = useTooltipInPortal({
//     scroll: true,
//   });

//   if (width < 10 || data.length === 0) return null;

//   // Calculate the maximum value for the y-scale
//   const maxY = max(data, (d) =>
//     colorDomain.reduce((acc, key) => acc + (d[key] || 0), 0)
//   );

//   // Scales
//   const dateScale = scaleBand({
//     domain: data.map(getQuarter),
//     padding: 0.2,
//   });

//   const yMax = height - margin.top - 30;
//   const temperatureScale = scaleLinear({
//     domain: [0, maxY || 100], // Use maxY if available, otherwise fallback to 100
//     nice: true,
//   });

//   const colorScale = scaleOrdinal({
//     domain: colorDomain,
//     range: colors,
//   });

//   // bounds
//   const xMax = width - margin.left;
//   dateScale.rangeRound([0, xMax]);
//   temperatureScale.range([yMax, 0]);

//   return (
//     <div ref={containerRef} style={{ position: "relative" }}>
//       <svg width={width} height={height}>
//         <rect x={0} y={0} width={width} height={height} fill="#fff" rx={14} />

//         <Group left={margin.left} top={margin.top}>
//           <BarStack
//             data={data}
//             keys={colorDomain}
//             x={getQuarter}
//             xScale={dateScale}
//             yScale={temperatureScale}
//             color={colorScale}
//           >
//             {(barStacks) =>
//               barStacks.map((barStack) =>
//                 barStack.bars.map((bar) => (
//                   <rect
//                     key={`bar-stack-${barStack.index}-${bar.index}`}
//                     x={bar.x}
//                     y={bar.y}
//                     height={bar.height}
//                     width={bar.width}
//                     fill={bar.color}
//                     onClick={() => {
//                       if (events) alert(`clicked: ${JSON.stringify(bar)}`);
//                     }}
//                     onMouseLeave={() => {
//                       tooltipTimeout = window.setTimeout(() => {
//                         hideTooltip();
//                       }, 300);
//                     }}
//                     onMouseMove={(event) => {
//                       if (tooltipTimeout) clearTimeout(tooltipTimeout);
//                       const eventSvgCoords = localPoint(event);
//                       const left = bar.x + bar.width / 2;
//                       showTooltip({
//                         tooltipData: bar,
//                         tooltipTop: eventSvgCoords?.y + 10,
//                         tooltipLeft: eventSvgCoords?.x - 30,
//                       });
//                     }}
//                   />
//                 ))
//               )
//             }
//           </BarStack>
//         </Group>

//         <AxisBottom
//           hideTicks
//           hideAxisLine
//           top={yMax + margin.top}
//           left={margin.left}
//           scale={dateScale}
//           numTicks={width > 520 ? 10 : 5}
//           tickLabelProps={{
//             fontSize: 9,
//           }}
//         />
//         <AxisLeft
//           hideTicks
//           hideAxisLine
//           left={margin.left}
//           top={margin.top}
//           scale={temperatureScale}
//           label={leftLabel}
//           labelProps={{
//             dx: "1.15em",
//             fontWeight: 500,
//           }}
//           tickLabelProps={{
//             dx: "0.5em",
//             fontSize: 9,
//           }}
//           tickFormat={convertToK}
//         />
//       </svg>

//       {tooltipOpen && tooltipData && (
//         <TooltipInPortal
//           top={tooltipTop}
//           left={tooltipLeft}
//           style={{
//             ...defaultStyles,
//             width: "auto",
//             padding: 12,
//             backgroundColor: "white",
//             color: "black",
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             fontSize: 10,
//             gap: "3px",
//           }}
//         >
//           <div
//             style={{
//               backgroundColor: colorScale(tooltipData.key),
//               height: 15,
//               width: 15,
//             }}
//           ></div>
//           <div>
//             <span>{tooltipData.key}</span>
//           </div>
//           <div>{tooltipData.bar.data[tooltipData.key]}</div>
//         </TooltipInPortal>
//       )}
//     </div>
//   );
// }

import React from "react";
import { BarStack } from "@visx/shape";
import { Group } from "@visx/group";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { extent, max } from "d3-array";
import { convertToK } from "../../utils/convertToK";
import { LegendOrdinal } from "@visx/legend";

const defaultMargin = { top: 40, right: 0, bottom: 50, left: 40 }; // Increased bottom margin for legend
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 100,
  backgroundColor: "white",
  color: "black",
};

const getQuarter = (d) => d.quarter;

let tooltipTimeout;

export default function StackedBarChart({
  width,
  height,
  data = [],
  events = false,
  margin = defaultMargin,
  colors,
  leftLabel = "Score",
  colorDomain = [],
}) {
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  if (width < 10 || data.length === 0) return null;

  const maxY = max(data, (d) =>
    colorDomain.reduce((acc, key) => acc + (d[key] || 0), 0)
  );

  const dateScale = scaleBand({
    domain: data.map(getQuarter),
    padding: 0.2,
  });

  const yMax = height - margin.top - margin.bottom; // Adjusted for bottom margin
  const temperatureScale = scaleLinear({
    domain: [0, maxY || 100],
    nice: true,
  });

  const colorScale = scaleOrdinal({
    domain: colorDomain,
    range: colors,
  });

  const xMax = width - margin.left;
  dateScale.rangeRound([0, xMax]);
  temperatureScale.range([yMax, 0]);

  return (
    <div ref={containerRef} style={{ position: "relative" }}>
      <svg width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill="#fff" rx={14} />

        <Group left={margin.left} top={margin.top}>
          <BarStack
            data={data}
            keys={colorDomain}
            x={getQuarter}
            xScale={dateScale}
            yScale={temperatureScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => (
                  <rect
                    key={`bar-stack-${barStack.index}-${bar.index}`}
                    x={bar.x}
                    y={bar.y}
                    height={bar.height}
                    width={bar.width}
                    fill={bar.color}
                    onClick={() => {
                      if (events) alert(`clicked: ${JSON.stringify(bar)}`);
                    }}
                    onMouseLeave={() => {
                      tooltipTimeout = window.setTimeout(() => {
                        hideTooltip();
                      }, 300);
                    }}
                    onMouseMove={(event) => {
                      if (tooltipTimeout) clearTimeout(tooltipTimeout);
                      const eventSvgCoords = localPoint(event);
                      const left = bar.x + bar.width / 2;
                      showTooltip({
                        tooltipData: bar,
                        tooltipTop: eventSvgCoords?.y + 10,
                        tooltipLeft: eventSvgCoords?.x - 30,
                      });
                    }}
                  />
                ))
              )
            }
          </BarStack>
        </Group>

        <AxisBottom
          hideTicks
          hideAxisLine
          top={yMax + margin.top}
          left={margin.left}
          scale={dateScale}
          numTicks={width > 520 ? 10 : 5}
          tickLabelProps={{
            fontSize: 9,
          }}
        />
        <AxisLeft
          hideTicks
          hideAxisLine
          left={margin.left}
          top={margin.top}
          scale={temperatureScale}
          label={leftLabel}
          labelProps={{
            dx: "1.15em",
            fontWeight: 500,
          }}
          tickLabelProps={{
            dx: "0.5em",
            fontSize: 9,
          }}
          tickFormat={convertToK}
        />

        {/* Legend inside SVG */}
        <Group left={margin.left} top={height - margin.bottom + 30}>
          <foreignObject width={width - margin.left} height={margin.bottom}>
            <div style={{ width: "100%", height: "100%" }}>
              <LegendOrdinal
                scale={colorScale}
                direction="row"
                itemMargin="5px"
                shape="circle"
                shapeHeight={10}
                shapeWidth={10}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 10,
                  flexWrap: "wrap",
                }}
              />
            </div>
          </foreignObject>
        </Group>
      </svg>

      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            width: "auto",
            padding: 12,
            backgroundColor: "white",
            color: "black",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 10,
            gap: "3px",
          }}
        >
          <div
            style={{
              backgroundColor: colorScale(tooltipData.key),
              height: 15,
              width: 15,
            }}
          ></div>
          <div>
            <span>{tooltipData.key}</span>
          </div>
          <div>{tooltipData.bar.data[tooltipData.key]}</div>
        </TooltipInPortal>
      )}
    </div>
  );
}
