import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import StackedBarChart from "./StackedBarChart";
import PieChart from "./PieChart";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import BarChart from "./BarChart";
import HalfDoughnutChart from "./HalfDoughnutChart";
import MultipleLineAreaChart from "./MultipleLineAreaChart";
import DoughnutChart from "./DoughnutChart";
import CurvedLineChart from "./CurvedLineChart";
import HorizontalBarChart from "./HorizontalBarChart";
import totalElectricity from "../../assets/sustainabilityReport/totalElectricity.svg";
import totalSox from "../../assets/sustainabilityReport/totalSOX.svg";
import totalNOX from "../../assets/sustainabilityReport/totalNOX.svg";
import totalGHG from "../../assets/sustainabilityReport/totalGHG.svg";
import waterDrops from "../../assets/sustainabilityReport/waterDrops.svg";
import LevelDoughnutChart from "./LevelDoughnutChart";
import {
  transformAndSortWaterConsumptionTrend,
  transformDataForElectricity,
  transformDataForGHGEmissions,
  transformDataForSoxNox,
  transformDataForWasteHorizontal,
  transformWasteDataForTrend,
} from "../../utils/reportPage";

const EnvStatsCard = ({ icon, title, amount }) => {
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "#E6F8F2",
        p: "1.25rem",
        borderRadius: "12px",
      }}
    >
      <img
        src={icon}
        alt={title}
        height={30}
        width={30}
        style={{ marginBottom: "6px" }}
      />
      <Box sx={{ minHeight: "44px", mb: 0.0 }}>
        {" "}
        <h4 className="base-sector-card_title">{title}</h4>
      </Box>
      <Typography variant="h5" sx={{ fontSize: "18px", fontWeight: 500 }}>
        {amount?.toLocaleString("en-IN")}
      </Typography>
    </Box>
  );
};

const EnvironmentalStats = ({ report, cleanElectricityFactor }) => {
  const { environmentKPI, basicSectorSpecificKPI } = report;
  // console.log(basicSectorSpecificKPI);
  // console.log(report);
  console.log("amount",environmentKPI?.E1?.scope1Emissions?.value)
  console.log(environmentKPI);
  // E3: waterConsumed  B2:totalWaterConsumption
  const last6yearsReportdata = [report, ...report?.last6YearsReports];

  const cardData = [
    {
      id: 0,
      icon: totalGHG,
      title: "Total GHG emissions (tCO2e)",

      amount:
        Number(environmentKPI?.E1?.scope1Emissions?.value || 0) +
        Number(environmentKPI?.E1?.scope2Emissions?.value || 0) +
        Number(environmentKPI?.E2?.scope3Emissions?.value || 0),
    },
    // scope1 here in second
    {
      id: 1,
      icon: totalSox,
      title: "Total SOx emissions (tonnes)",
      amount: report?.environmentKPI?.E6?.soxReleased?.value,
    },
    {
      id: 2,
      icon: totalNOX,
      title: "Total NOx emissions (tonnes)",
      amount: report?.environmentKPI?.E5?.noxReleased?.value,
    },
    {
      id: 3,
      icon: totalElectricity,
      title: "Total Electricity Consumption (MWh)",
      amount:
        report?.basicSectorSpecificKPI?.B6?.totalElectricityConsumption?.value,
    },
    {
      id: 4,
      icon: waterDrops,
      title: " Water Consumption (m3)",
      amount: basicSectorSpecificKPI?.B2?.totalWaterConsumption?.value,
    },
  ];

  const emissionsPie = [
    { label: "Scope 1", value: environmentKPI?.E1?.scope1Emissions?.value },
    { label: "Scope 2", value: environmentKPI?.E1?.scope2Emissions?.value },
    { label: "Scope 3", value: environmentKPI?.E2?.scope3Emissions?.value },
  ];

  const electricityConsumptionCalc = () => {
    const electricityFromRenewables =
      basicSectorSpecificKPI?.B6?.electricityFromRenewables?.value;
    const electricityFromGrid =
      basicSectorSpecificKPI?.B6?.electricityFromGrid?.value;
    const recPurchased = basicSectorSpecificKPI?.B6?.recPurchased?.value;

    const cleanElectricity =
      cleanElectricityFactor * electricityFromGrid +
      recPurchased +
      electricityFromRenewables;

    // const percentage = (cleanElectricity / totalElectricityConsumption) * 100;

    return cleanElectricity;
  };

  const electricityHalfDoughnut = [
    {
      label: "Percentage of clean electricity",
      value: electricityConsumptionCalc(),
    },
    {
      label: "Percentage of electricity from conventional sources",
      value:
        basicSectorSpecificKPI?.B6?.totalElectricityConsumption?.value -
        electricityConsumptionCalc(),
    },
  ];

  const wasteTreatmentPie = [
    {
      label: "Treated",
      value:
        basicSectorSpecificKPI?.B3?.totalHazardousWaste?.value +
        basicSectorSpecificKPI?.B4?.totalNonHazardousWaste?.value -
        environmentKPI?.E4?.wasteToLandfill?.value,
    },
    { label: "Landfill", value: environmentKPI?.E4?.wasteToLandfill?.value },
  ];

  const wasteGenerationPie = [
    {
      label: "Hazardous Waste",
      value: basicSectorSpecificKPI?.B3?.totalHazardousWaste?.value,
    },
    {
      label: "Non-Hazardous Waste",
      value: basicSectorSpecificKPI?.B4?.totalNonHazardousWaste?.value,
    },
  ];

  const wasteWaterTreatedDoughnut = [
    {
      label: "Waste Water Treated",
      value: environmentKPI?.E9?.wasteWaterTreated?.value,
    },
    {
      label: "Waste Water Untreated",
      value:
        environmentKPI?.E9?.totalWasteWater?.value -
        environmentKPI?.E9?.wasteWaterTreated?.value,
    },
  ];
  const percentOfWaterTeated =
    (environmentKPI?.E9?.wasteWaterTreated?.value /
      environmentKPI?.E9?.totalWasteWater?.value) *
    100;

  const data = [
    { label: "Incineration", value: 50 },
    { label: "Landfill", value: 30 },
    // { label: "SCOPE 3", value: 30 },
  ];

  const horizontalData = [
    {
      label: "Q1 2023",
      "Waste Water Treated": 20,
      "Waste Water Generated": 80,
    },
    {
      label: "Q2 2023",
      "Waste Water Treated": 30,
      "Waste Water Generated": 70,
    },
    {
      label: "Q3 2023",
      "Waste Water Treated": 25,
      "Waste Water Generated": 75,
    },
    {
      label: "Q4 2023",
      "Waste Water Treated": 80,
      "Waste Water Generated": 20,
    },
    {
      label: "Q1 2024",
      "Waste Water Treated": 65,
      "Waste Water Generated": 35,
    },
    {
      label: "Q2 2024",
      "Waste Water Treated": 65,
      "Waste Water Generated": 35,
    },
  ];

  const barData = [
    { quarter: "Q1 2023", Sox: 0, Nox: 10 },
    { quarter: "Q2 2023", Sox: 20, Nox: 30 },
    { quarter: "Q3 2023", Sox: 25, Nox: 35 },
    { quarter: "Q4 2023", Sox: 10, Nox: 40 },
    { quarter: "Q1 2024", Sox: 30, Nox: 50 },
    { quarter: "Q2 2024", Sox: 15, Nox: 40 },
  ];

  const formattedSoxNoxData = transformDataForSoxNox(
    last6yearsReportdata?.slice(-6)
  );

  const formattedGHGEmissionsData = transformDataForGHGEmissions(
    last6yearsReportdata?.slice(-5)
  );

  const data1 = [
    { quarter: "Q1 2023", value: 10 },
    { quarter: "Q2 2023", value: 20 },
    { quarter: "Q3 2023", value: 18 },
    { quarter: "Q4 2023", value: 12 },
    { quarter: "Q1 2024", value: 8 },
    { quarter: "Q2 2024", value: 5 },
  ];

  const data2 = [
    { quarter: "Q1 2023", value: 60 },
    { quarter: "Q2 2023", value: 50 },
    { quarter: "Q3 2023", value: 45 },
    { quarter: "Q4 2023", value: 50 },
    { quarter: "Q1 2024", value: 55 },
    { quarter: "Q2 2024", value: 40 },
  ];

  const formattedElectricityData = transformDataForElectricity(
    last6yearsReportdata?.slice(-6)
  );

  const formattedWaterConsumptionData = transformAndSortWaterConsumptionTrend(
    last6yearsReportdata?.slice(-6)
  );

  const formattedWasteGenerationTrend = transformWasteDataForTrend(
    last6yearsReportdata?.slice(-7)
  );

  const formattedWasteWaterHorizontal = transformDataForWasteHorizontal(
    last6yearsReportdata?.slice(-6)
  );
  console.log(formattedWasteWaterHorizontal);

  const colors = ["#02B880", "#B1E9D8", "#E1F4F3"];

  const formatPercentage = (value) => {
    // If it's a whole number, show without decimals
    if (value % 1 === 0) {
      return Math.floor(value) + "%";
    }
    // Otherwise show with 2 decimal places
    return value.toFixed(2) + "%";
  };

  return (
    <>
      {/* GREEN DIVS */}

      <h1 className="sustainability-section_heading">Environmental Index </h1>

      <Grid container spacing={2.5} sx={{ mb: "20px" }}>
        {cardData.map((item) => (
          <Grid key={item.id} item xs={2.4}>
            <EnvStatsCard {...item} />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2.5} sx={{ mb: "20px" }}>
        <Grid item xs={3}>
          <Box
            sx={{
              p: "16px 16px 32px 16px",
              display: "flex",
              flexDirection: "column",
              height: "21rem",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>
              Emissions Breakdown
              <span className="sustain-subheading-h2"> (In tCO2e)</span>
            </h2>
            <div style={{ height: "13.5rem" }}>
              <ParentSize>
                {({ width, height }) => (
                  <PieChart
                    colors={colors}
                    data={emissionsPie}
                    width={width}
                    height={height}
                    unit="tCO2e"
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>
        <Grid item xs={4.5}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>
              GHG Emission Tracker
              <span className="sustain-subheading-h2"> (million tCO2e)</span>
            </h2>
            <div
              style={{
                height: "267px",
                // border: "1px solid "
              }}
            >
              <ParentSize>
                {({ width, height }) => (
                  <StackedBarChart
                    colorDomain={["Scope 1", "Scope 2", "Scope 3"]}
                    colors={["#E1F4F3", "#B1E9D8", "#02B880"]}
                    data={formattedGHGEmissionsData}
                    width={width}
                    height={height}
                    leftLabel="million tCO2e"
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>
        <Grid item xs={4.5}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>
              SOx and NOx emissions
              <span className="sustain-subheading-h2"> (tonnes)</span>
            </h2>
            <div style={{ height: "267px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <BarChart
                    colors={colors}
                    data={formattedSoxNoxData}
                    width={width}
                    height={height}
                    labelLeft={"tonnes"}
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2.5} sx={{ mb: "20px" }}>
        <Grid item xs={3}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "21rem",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>
              Electricity Consumption Breakdown
              <span className="sustain-subheading-h2"> (MWh)</span>
            </h2>
            <div style={{ height: "220px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <HalfDoughnutChart
                    colors={colors}
                    data={electricityHalfDoughnut}
                    width={width}
                    height={height}
                    unit="MWh"
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>
              Clean Electricity Consumption Trend
              <span className="sustain-subheading-h2"> (MWh)</span>
            </h2>
            <div style={{ height: "264px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <MultipleLineAreaChart
                    // colors={colors}
                    // data={data}
                    colors={{
                      line1: "#02b880",
                      line2: "#b1e9d8",
                      gradient1: "#41c89f",
                      gradient2: "#c8efe3",
                    }}
                    data1={formattedElectricityData?.data1}
                    data2={formattedElectricityData?.data2}
                    width={width}
                    height={height}
                    tooltipLabel1="Clean electricity"
                    tooltipLabel2="Electricity from conventional sources"
                    unit="MWh"
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>
              Waste Treatment
              <span className="sustain-subheading-h2"> (tonnes)</span>
            </h2>
            <div style={{ height: "220px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <PieChart
                    colors={colors}
                    data={wasteTreatmentPie}
                    width={width}
                    height={height}
                    unit="tonnes"
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2.5} sx={{ mb: "20px" }}>
        <Grid item xs={9} sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              p: 2,
              height: "100%",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>
              Water consumption trend
              <span className="sustain-subheading-h2"> (m³)</span>
            </h2>
            <div style={{ height: "267px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <CurvedLineChart
                    width={width}
                    height={height}
                    color="#02B880"
                    leftLabel="m³"
                    data={formattedWaterConsumptionData}
                    tooltipLabel="Water Consumption"
                    unit="m³"
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              p: 2,
              height: "21rem",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>
              Percentage Split between Waste Water Generated and Treated
              <span className="sustain-subheading-h2"> (%)</span>
            </h2>
            <div style={{ height: "240px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <HorizontalBarChart
                    colors={["#02B880", "#E6F8F2"]}
                    // data={horizontalData}
                    data={formattedWasteWaterHorizontal}
                    width={width}
                    height={height}
                    showLegend
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2.5} sx={{ mb: "60px" }}>
        <Grid item xs={3} sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              p: 2,
              flexGrow: 1,
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>
              Waste Generation
              <span className="sustain-subheading-h2"> (tonnes)</span>
            </h2>
            <div style={{ height: "220px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <PieChart
                    colors={colors}
                    data={wasteGenerationPie}
                    width={width}
                    height={height}
                    unit="tonnes"
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              p: 2,
              flexGrow: 1,
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>Waste Generation (Hazardous and Non-Hazardous)</h2>
            <div style={{ height: "267px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <StackedBarChart
                    // colors={colors}
                    colors={["#02B880", "#B1E9D8"]}
                    // data={barData}
                    data={formattedWasteGenerationTrend}
                    width={width}
                    height={height}
                    leftLabel="tonnes"
                    colorDomain={["Hazardous", "Non-Hazardous"]}
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              p: 2,
              height: "21rem",
              backgroundColor: "#FFF",
              borderRadius: "12px",
            }}
          >
            <h2>
              Waste Water Treated
              <span className="sustain-subheading-h2"> (m³)</span>
            </h2>
            <div style={{ height: "227px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <DoughnutChart
                    colors={colors}
                    data={wasteWaterTreatedDoughnut}
                    width={width}
                    height={height}
                    centerText={formatPercentage(percentOfWaterTeated)}
                    subText="Percentage of total waste water treated"
                    unit="m³"
                  />
                )}
              </ParentSize>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default EnvironmentalStats;
