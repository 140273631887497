import React from "react";
import Pie from "@visx/shape/lib/shapes/Pie";
import { scaleOrdinal } from "@visx/scale";
import { Group } from "@visx/group";
import { LegendOrdinal } from "@visx/legend";
import { animated, useTransition, to } from "@react-spring/web";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { localPoint } from "@visx/event";

// Accessor functions
const value = (d) => d.value;

const defaultMargin = { top: 20, right: 20, bottom: 10, left: 20 };

export default function DoughnutChart({
  data = [], // Default to an empty array if data is not provided
  colors,
  width,
  height,
  margin = defaultMargin,
  animate = true,
  centerText = "", // New prop for center text
  subText = "", // Subtext
  subTextMaxLength = 20, // Set the maximum length of subText per line
  unit = "", // Optional unit
}) {
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    showTooltip,
    hideTooltip,
    tooltipData,
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  // Check if data is empty or all values are 0
  const isEmpty = !data?.length || data.every((item) => !item.value);

  // If empty, create placeholder data
  const chartData = isEmpty
    ? [
        { label: "Empty", value: 1 },
        { label: "Empty", value: 1 },
      ]
    : data;

  const totalValue = chartData.reduce((acc, d) => acc + d.value, 0);

  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const innerRadius = radius * 0.8;

  // Split the subtext into multiple lines if it's too long
  const subTextLines = subText
    ? subText.match(new RegExp(`.{1,${subTextMaxLength}}`, "g")) || [subText]
    : [];

  // Color scale - use gray for empty state
  const colorScale = scaleOrdinal({
    domain: chartData.map((d) => d.label),
    range: isEmpty ? ["#E5E5E5", "#F0F0F0"] : colors,
  });

  // Parent container styles with blur effect
  const containerStyle = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: isEmpty ? 0.6 : 1,
    filter: isEmpty ? "blur(1px)" : "none",
    pointerEvents: isEmpty ? "none" : "auto",
    transition: "all 0.3s ease-in-out",
  };

  return (
    <div style={containerStyle} ref={containerRef}>
      <svg width={width} height={height}>
        <Group top={centerY + margin.top} left={centerX + margin.left}>
          {/* Center text */}
          <text
            x={0}
            y={-10}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            {isEmpty ? "0%" : centerText}
          </text>

          {/* Subtext */}
          {subText &&
            subTextLines.map((line, index) => (
              <text
                key={index}
                x={0}
                y={10 + index * 11}
                textAnchor="middle"
                dominantBaseline="middle"
                style={{
                  fontSize: 10,
                  fontWeight: "500",
                }}
              >
                {line}
              </text>
            ))}

          <Pie
            data={chartData}
            pieValue={value}
            outerRadius={radius}
            innerRadius={innerRadius}
          >
            {(pie) => (
              <AnimatedPie
                {...pie}
                animate={animate}
                getKey={(arc) => arc.data.label}
                getColor={(arc) => colorScale(arc.data.label)}
                getPercentage={(arc) =>
                  ((arc.data.value / totalValue) * 100).toFixed(2)
                }
                onHoverDatum={(arc, event) => {
                  if (!isEmpty) {
                    const eventSvgCoords = localPoint(event);
                    showTooltip({
                      tooltipTop: eventSvgCoords.y,
                      tooltipLeft: eventSvgCoords.x,
                      tooltipData: {
                        ...arc.data,
                        percentage: (
                          (arc.data.value / totalValue) *
                          100
                        ).toFixed(2),
                      },
                    });
                  }
                }}
                onLeaveDatum={hideTooltip}
              />
            )}
          </Pie>
        </Group>
      </svg>

      {/* Tooltip - only show when not empty */}
      {!isEmpty && tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            backgroundColor: "white",
            padding: 12,
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
            borderRadius: "4px",
            color: "black",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              style={{
                backgroundColor: colorScale(tooltipData.label),
                height: 15,
                width: 15,
              }}
            ></div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div>
                <span style={{ color: "#BDBDBD" }}>{tooltipData.label}</span>
              </div>
              <div
                style={{
                  height: "3px",
                  width: "3px",
                  borderRadius: "100%",
                  backgroundColor: "#BDBDBD",
                }}
              ></div>
              <div style={{ color: "#717171" }}>{tooltipData.percentage}%</div>
              <div
                style={{
                  height: "3px",
                  width: "3px",
                  borderRadius: "100%",
                  backgroundColor: "#BDBDBD",
                }}
              ></div>
              <div>
                {tooltipData.value.toLocaleString("en-IN")}
                 {/* {unit} */}
              </div>
            </div>
          </div>
        </TooltipInPortal>
      )}

      <div
        style={{
          marginTop: 10,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LegendOrdinal
          scale={colorScale}
          direction="row"
          itemMargin="5px"
          shape="circle"
          shapeHeight={10}
          shapeWidth={10}
          style={{
            display: "flex",
            justifyContent: "center",
            // marginTop: 10,
            fontSize: 10,
            flexWrap: "wrap",
            // border:'1px solid red'
          }}
        />
      </div>
    </div>
  );
}

// react-spring transition definitions
const fromLeaveTransition = ({ endAngle }) => ({
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});

const enterUpdateTransition = ({ startAngle, endAngle }) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

function AnimatedPie({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  getPercentage,
  onHoverDatum,
  onLeaveDatum,
}) {
  const transitions = useTransition(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });

  return transitions((props, arc, { key }) => {
    const [centroidX, centroidY] = path.centroid(arc);

    return (
      <g
        key={key}
        onMouseEnter={(event) => onHoverDatum(arc, event)}
        onMouseMove={(event) => onHoverDatum(arc, event)}
        onMouseLeave={onLeaveDatum}
      >
        <animated.path
          d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            })
          )}
          fill={getColor(arc)}
        />
      </g>
    );
  });
}
