function filterRejectedKPIs(report) {
  // Function to filter based on approval status of 'rejected'
  const filterKPIs = (kpiSection) => {
    if (!kpiSection || typeof kpiSection !== "object") return null;

    // Iterate over each KPI in the section and filter based on the approval status
    const filteredKPIs = Object.keys(kpiSection).reduce((acc, key) => {
      const kpi = kpiSection[key];

      // We check if the KPI has an approval key with the status 'rejected'
      if (kpi?.approval?.status === "rejected") {
        acc[key] = kpi; // Only add if approval status is 'rejected'
      }

      return acc;
    }, {});

    // Only return if there is at least one KPI that matches the criteria
    return Object.keys(filteredKPIs).length > 0 ? filteredKPIs : null;
  };

  // Create the filtered report, only including sections that have at least one KPI with status 'rejected'
  const filteredReport = {};

  const basicKPIs = filterKPIs(report?.basicSectorSpecificKPI);
  if (basicKPIs) filteredReport.basicSectorSpecificKPI = basicKPIs;

  const environmentKPIs = filterKPIs(report?.environmentKPI);
  if (environmentKPIs) filteredReport.environmentKPI = environmentKPIs;

  const socialKPIs = filterKPIs(report?.socialKPI);
  if (socialKPIs) filteredReport.socialKPI = socialKPIs;

  const governanceKPIs = filterKPIs(report?.governanceKPI);
  if (governanceKPIs) filteredReport.governanceKPI = governanceKPIs;

  // Return only if filteredReport has keys (i.e., there are filtered results)
  return Object.keys(filteredReport).length > 0 ? filteredReport : null;
}

const allKeyMappings = {
  // base sector
  scope1Emissions: "Scope 1 emissions",
  scope2Emissions: "Scope 2 emissions",
  grossRevenue: "Gross Revenue",
  totalWaterConsumption: "Total Water Consumption",
  totalHazardousWaste: "Total Hazardous Waste Produced",
  totalNonHazardousWaste: "Total Non-Hazardous Waste Produced",
  totalElectricityConsumption: "Total Electricity Consumption (TEC)",
  electricityFromGrid: "Electricity Consumed from Grid (ECG)",
  recPurchased: "REC Purchased for Electricity (REC)",
  electricityFromRenewables: "Electricity from Renewables (ER)",
  Y1: "2021",
  Y2: "2022",
  Y3: "2023",

  scope3Emissions: "Scope 3 emissions",
  waterConsumed: "Total Water consumption",
  wasteToLandfill: "Amount of waste disposed to landfill",
  noxReleased: "Amount of NOx released into the environment",
  soxReleased: "Amount of SOx released into the environment",
  h2sConcentration:
    "Threshold H2S concentration for the flare technology in use",
  noiseLevel: "LA90 Noise level",
  totalWasteWater: "Total waste water generated",
  wasteWaterTreated: "Total waste water treated before disposal",

  //social
  totalMen: "Total Number of men in the workforce",
  totalWomen: "Total Number of women in the workforce",
  totalNonBinary:
    "Total Number of people in the workforce who identify as non-binary",
  foreignEmployees: "Number of foreign nationals in work-force",
  directEmployees: "Number of Direct employees",
  indirectEmployees:
    "Number of Indirect employees (contractors, outsourced etc)",
  averageTenure: "Average tenure of employees in the organisation",
  trainingHours: "Average number of Hours of training provided to employees",
  nonDiscriminatoryPolicy: "Do you have a Non-discriminatory policy in place?",
  trir: "TRIR (total recordable incident rate)",
  campusHires: "Total number of employees hired through campus placements",

  // governance
  taxReliefReceived:
    "Does your company/Product receive Tax Relief/Incentives/Subsidies from the Government?",
  pensionContribution: "Percentage of pension salary contributed by employer",
  localSuppliersSpending: "Amount spent on local suppliers",
  newSuppliersSpending:
    "Amount spent on suppliers having registered business less than 5 years",
  localCommunitiesSpending: "Amount spent on local communities",
  innovativeTechnologiesSpending: "Amount spent on innovative technologies",
  ethicsPolicyInPlace: "Do you have an Ethics policy in place?",
  totalComplaints: "Total Number of complains",
  resolvedComplaints: "Total Number of complains resolved",
  boardMembers: "Number of Board members",
  csrSpending: "Amount spent on CSR activities",
};

export { filterRejectedKPIs, allKeyMappings };
