import React, { useEffect, useState } from "react";
import DashboardNav from "../../Dashboard/TopBar";
import "./teamMembers.css";
import back from "../../../assets/ArrowLeft.svg";
import search from "../../../assets/search.svg";
import plus from "../../../assets/plus.svg";
import TeamImg from "../../../assets/TeamMemberImg.png";

import {
  Tabs,
  Tab,
  Box,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Button,
} from "@mui/material";
import AllMembers from "./AllMembers";
import PendingMembers from "./PendingMembers";
import AddMembers from "./AddMembers";
import PageHeading from "../../common/PageHeading/PageHeading";
import { Link, useNavigate } from "react-router-dom";
import { activePlan } from "../../../api/subscription";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="tab-panel"
      {...other}
    >
      {value === index && <div className="tab-panel_child">{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TeamMembers = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0); // Initialize with 0 for the first tab
  const [isAddMembersOpen, setIsAddMembersOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [activePlanData, setActivePlanData] = useState(null);

  useEffect(() => {
    const fetchActivePlan = async () => {
      try {
        const response = await activePlan();
        setActivePlanData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchActivePlan();
  }, []);

  console.log("activePlanData", activePlanData?.plan_name);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const memberTabs = [
    {
      id: 0,
      name: "All Members",
      component: <AllMembers searchQuery={searchQuery} />,
    },
    {
      id: 1,
      name: "Pending Invites",
      component: <PendingMembers searchQuery={searchQuery} />,
    },
  ];

  return (
    <>
      {activePlanData?.plan_name === "Premium" ? (
        <div className="team-members_container">
          {/* <div className="team-members_heading_container">
            <button type="button" className="back-button">
              <img src={back} alt="Go Back" />
            </button>
            <h1>Team Members</h1>
          </div> */}
          <PageHeading
            onClick={() => {
              navigate(-1);
            }}
            text={"Team Members"}
          />

          <div className="team-members-input_container">
            <FormControl variant="outlined">
              <OutlinedInput
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{
                  borderRadius: "8px",
                  maxHeight: "40px",
                }}
                type="search"
                className="reports-table-input"
                id="outlined-adornment-weight"
                startAdornment={
                  <InputAdornment position="start">
                    <img src={search} alt="Search" height={19} width={19} />
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />
            </FormControl>

            <button
              className="team-members-add_member"
              onClick={() => setIsAddMembersOpen(true)}
            >
              <img src={plus} alt="Add" height={18} width={18} />
              <span>Add Member</span>
            </button>
          </div>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: "100%", // Ensure the container takes up the full height
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: {
                  background:
                    "linear-gradient(101.74deg, #369D9C 0%, #28814D 100%)",
                },
              }}
              sx={{
                "& .MuiTab-root": {
                  textTransform: "none", // Remove uppercase transformation
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#666666D9", // Set non-active tab text to gray
                },
                "& .Mui-selected": {
                  color: "transparent", // Make the selected tab text transparent to show the gradient
                  background:
                    "linear-gradient(101.74deg, #369D9C 0%, #28814D 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                },
              }}
            >
              {memberTabs.map((item) => (
                <Tab key={item.id} label={item.name} {...a11yProps(item.id)} />
              ))}
            </Tabs>

            <TabPanel value={value} index={value}>
              {memberTabs[value].component}
            </TabPanel>
          </Box>
        </div>
      ) : (
        // <div
        //   style={{
        //     height: "100vh",
        //     display: "flex",
        //     justifyContent: "center",
        //     alignItems: "center",
        //   }}
        // >
        //   <div
        //     style={{
        //       backgroundColor: "rgba(249, 255, 252, 1)",
        //       height: "85%",
        //       width: "75%%",
        //       display: "flex",
        //       justifyContent: "center",
        //       alignItems: "center",
        //       flexDirection: "column",
        //       borderRadius: "5px",
        //       gap: "15px",
        //       textAlign: "center",
        //       paddingTop: "45px",
        //       paddingBottom: "50px",

        //       // padding:"50px"
        //     }}
        //   >
        //     <div>
        //
        //     </div>
        //   </div>
        // </div>
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(249, 255, 252, 1)",
              width: "80%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "16px",
                marginTop: "30px",
              }}
            >
              Switch to Premium plan and bring your team onboard!
            </p>
            <img
              src={TeamImg}
              style={{ width: "350px", height: "350px" }}
              alt="Team"
            />
            <p
              style={{
                color: "rgba(128, 128, 128, 1)",
                fontFamily: "Inter",fontSize:"12px"
              }}
            >
              Unlock the power of teamwork by upgrading to the Premium plan and
              inviting your team members.
            </p>
            <Link
              to="/subscriptions"
              style={{
                borderRadius: "32px",
                background:
                  "linear-gradient(101.74deg, #369D9C 0%, #28814D 100%)",
                color: "white",
                padding: "15px 35px",
                textDecoration: "none",
                fontFamily: "Inter",
                marginBottom: "50px",
              }}
            >
              Upgrade now
            </Link>
          </div>
        </div>
      )}

      <AddMembers
        handleClose={() => setIsAddMembersOpen(false)}
        isOpen={isAddMembersOpen}
      />
    </>
  );
};

export default TeamMembers;
