import React, { useCallback, useEffect, useState } from "react";
import dummyUser from "../../../assets/dummyUser.png";
import messages from "../../../assets/messages.svg";
import group from "../../../assets/Group (2).svg";
import emptyuser from "../../../assets/emptyuser.avif";

import usePutApi from "../../../utils/hooks/usePutApi";
import { reportEndpoints } from "../../../api/endpoints/report.endpoints";
import { useLocation, useParams } from "react-router-dom";
import {
  dataPointsToNotMap,
  dataToNotSend,
  debounce,
  filterEmptyObjects,
  removeKeys,
  symbolsToNotMap,
  tooltipMapping,
} from "../../../utils/data-points/cleanObj";
import useFetch from "../../../utils/hooks/useFetch";
import { unitEndpoints } from "../../../api/endpoints/unit.endpoints";
import Loader from "../../Loader";
import { Tooltip } from "@mui/material";
import AssignModal from "./assignments/AssignModal";
import AssignButton from "../reports/rejected/AssignButton";
import { useTaggingStore } from "./assignments/chatStore";
import constant from "../../../constant";

const EnvironmentalKpi = () => {
  const params = useParams();
  const { put: saveProgress } = usePutApi(
    reportEndpoints.saveProgress(params.id)
  );
  const { data, loading } = useFetch(reportEndpoints.getReport(params.id));
  const basicSectorSpecificKPI = data?.report?.basicSectorSpecificKPI;
  console.log("basicSectorSpecificKPI.B1", basicSectorSpecificKPI);
  const reportData = data?.report?.environmentKPI;
  const { allUsers } = useTaggingStore();
  const {
    data: unitsData,
    loading: unitsLoading,
    error: unitsError,
  } = useFetch(unitEndpoints.getUnits);

  const location = useLocation();
  const isAiGenerated = location?.state?.generationMethod === "ai" || "";
  console.log(isAiGenerated);

  const headings = ["Symbol", "Data Point", "Data Point Unit", "Value"];

  const [dataToMap, setDataToMap] = useState({});
  const [basicData , setbasicData] = useState({});
  

  // Sync with reportData only if localStorage is empty
  useEffect(() => {
    if (!loading && reportData && basicSectorSpecificKPI && Object.keys(basicData).length === 0 && Object.keys(dataToMap).length === 0) {
      const { id, ...other } = reportData;
      const mergedData = { ...other };
      setDataToMap(mergedData);
      setbasicData(basicSectorSpecificKPI);
    }
  }, [reportData, loading]);

  const formatNumberWithCommas = (value) => {
    if (!value && value !== 0) return "";

    // Convert to string and remove existing commas
    const plainNumber = value.toString().replace(/,/g, "");

    // If it ends with a decimal point, preserve it
    if (plainNumber.endsWith(".")) {
      return plainNumber;
    }

    // Split into whole number and decimal parts
    const parts = plainNumber.split(".");

    // Format the whole number part with commas
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join back with decimal part if it exists
    return parts.join(".");
  };

  // const handleInputChange = (symbol, dataKey, value) => {
  //   // Remove commas for calculation
  //   const numericValue = value.replace(/,/g, "");

  //   // Allow empty string and standalone decimal point
  //   if (numericValue === "" || numericValue === ".") {
  //     setDataToMap((prevState) => ({
  //       ...prevState,
  //       [symbol]: {
  //         ...prevState[symbol],
  //         [dataKey]: {
  //           ...prevState[symbol][dataKey],
  //           value: numericValue,
  //         },
  //       },
  //     }));
  //     return;
  //   }

  //   // Check if it's a valid decimal number
  //   const isValidDecimal = /^\d*\.?\d*$/.test(numericValue);
  //   if (!isValidDecimal) return;

  //   let newValue;
  //   if (numericValue.endsWith(".")) {
  //     newValue = numericValue; // Preserve the decimal point while typing
  //   } else {
  //     newValue = parseFloat(numericValue);
  //     if (isNaN(newValue)) {
  //       newValue = "";
  //     }
  //   }

  //   setDataToMap((prevState) => {
  //     const updatedData = {
  //       ...prevState,
  //       [symbol]: {
  //         ...prevState[symbol],
  //         [dataKey]: {
  //           ...prevState[symbol][dataKey],
  //           value: newValue,
  //         },
  //       },
  //     };

  //     debouncedSaveProgress(updatedData);
  //     return updatedData;
  //   });
  // };
  
  
  
 
  const handleInputChange = (symbol, dataKey, value) => {
    // Remove commas for calculation
    const numericValue = value.replace(/,/g, "");

    console.log("symbol", symbol);
    console.log("dataKey", dataKey);
    console.log("value", value);

    // Allow empty string
    if (numericValue === "") {
      setDataToMap((prevState) => ({
        ...prevState,
        [symbol]: {
          ...prevState[symbol],
          [dataKey]: {
            ...prevState[symbol][dataKey],
            value: "",
          },
        },
      }));
      return;
    }

    // Allow input while typing decimals
    if (numericValue === ".") {
      setDataToMap((prevState) => ({
        ...prevState,
        [symbol]: {
          ...prevState[symbol],
          [dataKey]: {
            ...prevState[symbol][dataKey],
            value: "0.",
          },
        },
      }));
      return;
    }

    // Check if it's a valid number with up to 2 decimal places
    const isValidNumber = /^(?:\d*\.?\d{0,2}|\.\d{0,2})$/.test(numericValue);
    if (!isValidNumber) return;

    let newValue = numericValue;

    // Only convert to float for percentage validation
    if (dynamicUnitMapping[dataToMap[symbol][dataKey]?.unit] === "%") {
      const numericValueForValidation = parseFloat(numericValue);
      if (
        !isNaN(numericValueForValidation) &&
        numericValueForValidation > 100
      ) {
        newValue = "100";
      }
    }

    setDataToMap((prevState) => {
      const updatedData = {
        ...prevState,
        [symbol]: {
          ...prevState[symbol],
          [dataKey]: {
            ...prevState[symbol][dataKey],
            value: newValue,
          },
        },
      }
      debouncedSaveProgress(updatedData);
      return updatedData;
    })
  };

  const sendPutRequest = (updatedData) => {
    const keysRemoved = removeKeys(updatedData, dataToNotSend);
    const filteredData = filterEmptyObjects(keysRemoved);

    console.log("Sending PUT request with data:", filteredData);

    // Call the API with the updated data
    saveProgress({ environmentKPI: filteredData })
      .then((response) => {
        console.log("PUT request successful:", response.data);
      })
      .catch((error) => {
        console.error("PUT request failed:", error);
      });
  };

  const debouncedSaveProgress = useCallback(
    debounce((updatedData) => {
      sendPutRequest(updatedData); // Use updated data here
    }, 500),
    []
  );

  const keyMapping = {
    scope1Emissions: "Scope 1 emissions",
    scope2Emissions: "Scope 2 emissions",
    scope3Emissions: "Scope 3 emissions",
    waterConsumed: "Total Water consumption",
    wasteToLandfill: "Amount of waste disposed to landfill",
    noxReleased: "Amount of NOx released into the environment",
    soxReleased: "Amount of SOx released into the environment",
    h2sConcentration:
      "Threshold H2S concentration for the flare technology in use",
    noiseLevel: "LA90 Noise level",
    totalWasteWater: "Total waste water generated",
    wasteWaterTreated: "Total waste water treated before disposal",
  };
  const dataPointTooltipMapping = {
    scope1Emissions:
      "Emissions from sources that are owned or controlled by the company, such as fuels burned on site or company-owned vehicles.",
    scope2Emissions:
      "Emissions from the generation of purchased electricity, steam, heating, and cooling needs of the company.",
    scope3Emissions:
      "Emissions from sources not owned or directly controlled by the company but related to the company's operations, like supplier manufacturing and product use.",
    waterConsumed:
      "The total volume of water used by the company for all operations within a given period.",
    wasteToLandfill:
      "The total amount of waste the company has sent to landfill sites.",
    noxReleased:
      "Total nitrogen oxides released into the environment, typically from combustion processes.",
    soxReleased:
      "Total sulfur oxides released into the environment, generally from burning fossil fuels.",
    h2sConcentration:
      "The maximum allowable concentration of hydrogen sulfide that can be released by the company’s flare systems",
    noiseLevel:
      "The noise level that is exceeded 90% of the time in company premises due to company activities.",
    totalWasteWater:
      "The total volume of wastewater produced by the company during its operations.",
    wasteWaterTreated:
      "The volume of wastewater that has been treated to remove contaminants before being disposed.",
  };

  // Create a mapping of unit IDs to unit names from unitsData
  const dynamicUnitMapping = unitsData?.data?.reduce((acc, unit) => {
    acc[unit.id] = unit.name;
    return acc;
  }, {});

  const isMessages = true;

  const [modalState, setModalState] = useState({
    visible: false,
    top: 0,
    left: 0,
    symbol: null, // Track which symbol triggered the modal
    code: "",
    kpiName: "",
    alreadyTagged: [],
  });

  const handleAssignClick = (event, symbol, alreadyTagged) => {
    const buttonRect = event.target.getBoundingClientRect(); // Get button position
    const maxModalHeight = 550; // Max height of the modal

    const viewportHeight = window.innerHeight; // Total available viewport height
    const spaceBelow = viewportHeight - buttonRect.bottom; // Space below the button
    const spaceAbove = buttonRect.top; // Space above the button

    // Decide whether to position the modal above or below the button
    let topPosition;
    let modalHeight; // The height of the modal

    if (spaceBelow >= maxModalHeight) {
      // Enough space below the button, place the modal below
      topPosition = buttonRect.bottom;
      modalHeight = maxModalHeight; // Max height because there's enough space below
    } else if (spaceAbove >= maxModalHeight) {
      // Not enough space below, but enough space above, place the modal above
      topPosition = buttonRect.top - maxModalHeight;
      modalHeight = maxModalHeight; // Max height because there's enough space above
    } else if (spaceBelow > spaceAbove) {
      // More space below, use available space below
      topPosition = buttonRect.bottom;
      modalHeight = spaceBelow; // Set height to available space below
    } else {
      // More space above, use available space above
      topPosition = buttonRect.top - spaceAbove;
      modalHeight = spaceAbove; // Set height to available space above
    }

    // Adjust left position, ensuring the modal fits within the viewport width
    const modalWidth = 500; // Assume modal width
    const leftPosition = Math.min(
      buttonRect.right - modalWidth,
      window.innerWidth - modalWidth
    );

    // Set modal state with dynamically calculated top, left, and height
    setModalState({
      visible: true,
      top: topPosition,
      left: leftPosition,
      symbol, // Store the clicked symbol
      height: modalHeight, // Dynamically set the height based on available space
      code: symbol,
      kpiName: "environmentKPI",
      alreadyTagged,
    });
  };

  // Hide the modal
  const closeModal = () => {
    setModalState((prev) => ({
      ...prev,
      visible: false,
      // top: 0,
      // left: 0,
      // symbol: null,
    }));
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="new-kpi-table-container">
        <div className="new-kpi-table">
          {/* Table Header */}
          <div className="new-kpi-table-header">
            <div className="new-kpi-table-row new-kpi-table-head-row">
              {headings?.map((heading, index) => (
                <div
                  key={index}
                  className={`new-kpi-table-cell header-cell ${
                    index === 0 || index > 2 ? "centered" : ""
                  }`}
                >
                  <div className="new-kpi-table-header-content">{heading}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Table Body */}

          {/* Table Body */}
          {!loading && !unitsLoading ? (
            <div className="new-kpi-table-body">
              {Object?.keys(dataToMap)?.map((symbol) => {
                if (symbolsToNotMap.includes(symbol)) return null;

                // Check if there are any missing values for the symbol
                const isSymbolMissingValue = Object.keys(
                  dataToMap[symbol]
                ).some(
                  (dataKey) =>
                    !dataPointsToNotMap.includes(dataKey) &&
                    (dataToMap[symbol][dataKey]?.value === undefined ||
                      dataToMap[symbol][dataKey]?.value === "" ||
                      dataToMap[symbol][dataKey]?.value === null ||
                      !dataToMap[symbol][dataKey]?.value)
                );

                // Determine the background color for the symbol cell
                const symbolColor = !isAiGenerated
                  ? "inherit" // or "none" if you want no color applied, or any other fallback color
                  : isSymbolMissingValue
                  ? "" //#ffeded
                  : "#f6fffa";

                if (!dataToMap[symbol]?.assign) {
                  dataToMap[symbol] = {
                    ...dataToMap[symbol],
                    assign: { assignMembers: [] },
                  };
                }

                const assignMembers =
                  dataToMap[symbol]?.assign?.assignMembers || [];

                // Filter `teamMembers` to get only the members with IDs in `assignMembers`
                const assignedUsers =
                  allUsers?.filter((member) =>
                    assignMembers.includes(member.id)
                  ) || [];

                return (
                  <div
                    key={symbol}
                    className="new-kpi-table-row new-kpi-table-body-row"
                  >
                    {/* Symbol */}
                    <Tooltip
                      followCursor={true}
                      title={
                        <span style={{ fontSize: "12px", color: "black" }}>
                          {tooltipMapping[symbol] || "No description available"}
                        </span>
                      }
                      placement="top"
                      arrow
                      PopperProps={{
                        modifiers: [
                          {
                            name: "arrow",
                            options: {
                              padding: 5, // Offset the arrow a bit
                            },
                          },
                        ],
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "#E6F5F1", // Tooltip background color
                            color: "black", // Text color
                            fontSize: "12px", // Font size
                            padding: "8px", // Optional: Adjust padding
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#E6F5F1", // Arrow color (same as background)
                          },
                        },
                      }}
                    >
                      <div
                        className="new-kpi-table-cell centered"
                        style={{
                          backgroundColor: "rgba(246, 255, 250, 1)",
                          cursor: "pointer",
                        }}
                      >
                        <div className="new-kpi-table-cell-content">
                          {symbol}
                        </div>
                      </div>
                    </Tooltip>

                    {/* Data Points */}
                    <div className="new-kpi-table-cell">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific data point value is missing
                        const isDataPointMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null ||
                          !dataToMap[symbol][dataKey]?.value;
                        // Determine the background color for this specific data point
                        const cellColor = isAiGenerated
                          ? isDataPointMissing
                            ? "" // Red if missing
                            : "#f6fffa" // Green if present
                          : "";

                        // console.log(dataKey)

                        return (
                          <Tooltip
                            followCursor={true}
                            title={
                              <span
                                style={{ fontSize: "12px", color: "black" }}
                              >
                                {dataPointTooltipMapping[dataKey] ||
                                  "No description available"}
                              </span>
                            }
                            placement="top"
                            arrow
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "arrow",
                                  options: {
                                    padding: 5,
                                  },
                                },
                              ],
                            }}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#E6F5F1",
                                  color: "black",
                                  fontSize: "12px",
                                  padding: "8px",
                                },
                              },
                              arrow: {
                                sx: {
                                  color: "#E6F5F1",
                                },
                              },
                            }}
                          >
                            <div
                              key={idx}
                              className="new-kpi-table-cell-content data"
                              style={{
                                backgroundColor: "rgba(246, 255, 250, 1)",
                              }} // Apply background color to individual data points
                            >
                              {keyMapping[dataKey] || dataKey}
                              {dataKey.includes("scope") && (
                                <Tooltip
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        backgroundColor: "#fff", // Tooltip background color
                                        color: "black", // Text color
                                        fontSize: "12px", // Font size
                                        padding: "8px", // Optional: Adjust padding
                                        width: "402px",
                                        maxWidth: "none",
                                        boxShadow: "rgba(12, 12, 13, 0.05)",
                                      },
                                    },
                                    arrow: {
                                      sx: {
                                        color: "#fff", // Arrow color (same as background)
                                      },
                                    },
                                  }}
                                  title={
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "black",
                                        bgcolor: "white",
                                      }}
                                    >
                                      Calculate your Scope 1, Scope 2, Scope 3
                                      Emission with GHG Tool
                                    </span>
                                  }
                                  placement="right-start"
                                  arrow
                                >
                                  <div style={{ display: "flex" }}>
                                    <img
                                      src={group}
                                      alt=""
                                      style={{
                                        width: "12px",
                                        height: "12px",
                                        margin: "2px",
                                      }}
                                    />
                                    <a
                                      href="https://ghg.ge3s.org/login"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        textDecoration: "underline",
                                        color: "black",
                                      }}
                                    >
                                      GHG tool
                                    </a>
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                          </Tooltip>
                        );
                      })}
                    </div>

                    {/* Units */}
                    <div className="new-kpi-table-cell">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific data point value is missing for unit cell
                        const isDataPointMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null ||
                          !dataToMap[symbol][dataKey]?.value;

                        // Determine the background color for this specific unit cell
                        const cellColor = isAiGenerated
                          ? isDataPointMissing
                            ? "" // Red if missing
                            : "#f6fffa" // Green if present
                          : "";

                        return (
                          <div
                            key={idx}
                            className="new-kpi-table-cell-content data"
                            style={{
                              backgroundColor: "rgba(246, 255, 250, 1)",
                            }}
                          >
                            {/* {dynamicUnitMapping[
                              dataToMap[symbol][dataKey]?.unit
                            ] || "N/A"} */}
                            {(() => {
                              const unit =
                                dynamicUnitMapping[
                                  dataToMap[symbol][dataKey]?.unit
                                ] || "N/A";
                              return unit.toLowerCase() === "m3" ? "m³" : unit;
                            })()}
                          </div>
                        );
                      })}
                    </div>

                    {/* Values */}
                    <div className="new-kpi-table-cell centered">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific value is missing for value cell
                        const isValueMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null ||
                          !dataToMap[symbol][dataKey]?.value;

                        // Determine the background color for this specific value cell
                        const cellColor = isAiGenerated
                          ? isValueMissing
                            ? "" // Red if missing
                            : "#f6fffa" // Green if present
                          : "";

                        return (
                          <div
                            key={idx}
                            className="new-kpi-table-cell-content input-wrapper"
                            style={{ backgroundColor: cellColor }} // Apply background color to individual input cells
                          >
                            <input
                              onWheel={(e) => e.target.blur()}
                              type="text"
                              value={
                                dataToMap[symbol][dataKey]?.value !==
                                  undefined &&
                                dataToMap[symbol][dataKey]?.value !== null
                                  ? formatNumberWithCommas(
                                      dataToMap[symbol][dataKey].value
                                    )
                                  : ""
                              }
                              className="input-field"
                              placeholder="Enter value"
                              onChange={(e) =>
                                handleInputChange(
                                  symbol,
                                  dataKey,
                                  e.target.value
                                )
                              }
                              onKeyDown={(e) => {
                                // Allow numbers, single decimal point, and control keys
                                const hasDecimal = e.target.value.includes(".");
                                const isDecimalKey = e.key === ".";
                                const isNumber = /\d/.test(e.key);
                                const isControl = [
                                  "Backspace",
                                  "Delete",
                                  "ArrowLeft",
                                  "ArrowRight",
                                  "Tab",
                                ].includes(e.key);

                                // Block second decimal point
                                if (isDecimalKey && hasDecimal) {
                                  e.preventDefault();
                                  return;
                                }

                                // Allow only valid keys
                                if (!isNumber && !isControl && !isDecimalKey) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>

                    {/* Messages */}
                    <div className="new-kpi-table-cell centered">
                      <div className="new-kpi-table-cell-content">
                        {dataToMap[symbol]?.assign?.assignMembers?.length >
                        0 ? (
                          <div className="messages-wrapper">
                            <div className="profile-images-container">
                              {/* Show the first 3 profile images */}
                              {assignedUsers?.slice(0, 2)?.map((img) => (
                                <img
                                  key={`${constant.IMG_URL}/${img.profileImage}`}
                                  src={
                                    img.profileImage
                                      ? `${constant.IMG_URL}/${img.profileImage}`
                                      : emptyuser
                                  }
                                  onError={(e) => {
                                    e.target.src = emptyuser; // If image fails to load, fallback to empty user
                                  }}
                                  alt="Messaged"
                                  height={28}
                                  width={28}
                                />
                              ))}

                              {/* Show the count of remaining users if more than 3 */}
                              {assignedUsers.length > 2 && (
                                <div className="remaining-count">
                                  +{assignedUsers.length - 2}
                                </div>
                              )}
                            </div>
                            <div
                              onClick={(event) =>
                                handleAssignClick(event, symbol, assignMembers)
                              }
                              style={{ cursor: "pointer" }}
                              className="notifications-div"
                            >
                              <img
                                src={messages}
                                alt="Messaged"
                                height={24}
                                width={24}
                              />
                              {/* <span className="unread">2 Unread</span> */}
                              {dataToMap[symbol]?.unReadMessage > 0 && (
                                <span className="unread">
                                  {dataToMap[symbol]?.unReadMessage} Unread
                                </span>
                              )}
                            </div>
                          </div>
                        ) : (
                          <AssignButton
                            text="Assign"
                            onClick={(event) =>
                              handleAssignClick(event, symbol, assignMembers)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            // <p>LOADING...</p>
            <Loader />
          )}
        </div>
      </div>

      <AssignModal closeModal={closeModal} modalState={modalState} />
    </div>
  );
};

export default EnvironmentalKpi;
