import React, { useCallback, useEffect, useState } from "react";
import dummyUser from "../../../assets/dummyUser.png";
import messages from "../../../assets/messages.svg";
import usePutApi from "../../../utils/hooks/usePutApi";
import { reportEndpoints } from "../../../api/endpoints/report.endpoints";
import { useLocation, useParams } from "react-router-dom";
import useFetch from "../../../utils/hooks/useFetch";
import {
  dataPointsToNotMap,
  dataToNotSend,
  debounce,
  filterEmptyObjects,
  removeKeys,
  symbolsToNotMap,
  tooltipMapping,
} from "../../../utils/data-points/cleanObj";
import { unitEndpoints } from "../../../api/endpoints/unit.endpoints";
import Loader from "../../Loader";
import { Tooltip } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { useTaggingStore } from "../../owner/dataPoints/assignments/chatStore";
import AssignModal from "../../owner/dataPoints/assignments/AssignModal";
import constant from "../../../constant";
// import AssignModal from "./assignments/AssignModal";
// import AssignButton from "../reports/rejected/AssignButton";

const GovernanceKpi = () => {
  const { user } = useAuth();
  const params = useParams();
  const { put: saveProgress } = usePutApi(
    reportEndpoints.saveProgress(params.id)
  );
  const { data, loading } = useFetch(reportEndpoints.getReport(params.id));
  const reportData = data?.report?.governanceKPI;
  const {
    data: unitsData,
    loading: unitsLoading,
    error: unitsError,
  } = useFetch(unitEndpoints.getUnits);

  const location = useLocation();
  const isAiGenerated = location?.state?.generationMethod === "ai" || "";

  const { allUsers, assignMembersBySymbol } = useTaggingStore();

  const headings = [
    "Symbol",
    "Data Point",
    "Data Point Unit",
    "Value",
    // "Comments",
  ];

  const [dataToMap, setDataToMap] = useState({});

  useEffect(() => {
    setDataToMap({});
  }, [params.id]);

  useEffect(() => {
    if (!loading && reportData && Object.keys(dataToMap).length === 0) {
      const { id, ...other } = reportData;
      const mergedData = { ...other };
      setDataToMap(mergedData);
    }
  }, [reportData, loading]);

  const handleInputChange = (symbol, dataKey, value) => {
    const unit = dynamicUnitMapping[dataToMap[symbol][dataKey]?.unit];

    setDataToMap((prevState) => {
      let newValue;

      // Handle boolean values
      if (value === "true") {
        newValue = true;
      } else if (value === "false") {
        newValue = false;
      } else {
        newValue = parseFloat(value); // Convert to float for numeric values
      }

      // Check if the unit is '%' and limit the value to a maximum of 100
      if (unit === "%" && !isNaN(newValue)) {
        newValue = Math.min(newValue, 100); // Clamp the value to 100 for percentages
      }

      const updatedData = {
        ...prevState,
        [symbol]: {
          ...prevState[symbol],
          [dataKey]: {
            ...prevState[symbol][dataKey],
            value: newValue, // Update the value after validation
          },
        },
      };

      debouncedSaveProgress(updatedData); // Pass the updated data directly
      return updatedData;
    });
  };

  const sendPutRequest = (updatedData) => {
    const keysRemoved = removeKeys(updatedData, dataToNotSend);
    const filteredData = filterEmptyObjects(keysRemoved);

    console.log("Sending PUT request with data:", filteredData);

    // Call the API with the updated data
    saveProgress({ governanceKPI: filteredData })
      .then((response) => {
        console.log("PUT request successful:", response.data);
      })
      .catch((error) => {
        console.error("PUT request failed:", error);
      });
  };

  const debouncedSaveProgress = useCallback(
    debounce((updatedData) => {
      sendPutRequest(updatedData); // Use updated data here
    }, 500),
    []
  );

  const keyMapping = {
    taxReliefReceived:
      "Does your company/Product receive Tax Relief/Incentives/Subsidies from the Government?",
    pensionContribution: "Percentage of pension salary contributed by employer",
    localSuppliersSpending: "Amount spent on local suppliers",
    newSuppliersSpending:
      "Amount spent on suppliers having registered business less than 5 years",
    localCommunitiesSpending: "Amount spent on local communities",
    innovativeTechnologiesSpending: "Amount spent on innovative technologies",
    ethicsPolicyInPlace: "Do you have an Ethics policy in place?",
    totalComplaints: "Total Number of complaints",
    resolvedComplaints: "Total Number of complaints resolved",
    boardMembers: "Number of Board members",
    csrSpending: "Amount spent on CSR activities",
  };

  const dataPointTooltipMapping = {
    taxReliefReceived:
      "Indicates whether the company receives any financial benefits from the government to support its operations.",
    pensionContribution: "The percentage of employees' salaries that the company contributes to their pension plans.",
    localSuppliersSpending: "The amount of money spent on suppliers that are based in the same region as the company.",
    newSuppliersSpending:
      "The amount spent on suppliers that have been in business for less than five years.",
    localCommunitiesSpending: "The amount of money the company has invested in the local communities where it operates.",
    innovativeTechnologiesSpending: "The amount invested in developing or implementing innovative or efficient technologies.",
    ethicsPolicyInPlace: "Indicates whether the company adheres to a set of ethical guidelines that govern its operations and business conduct. This policy typically covers standards for honesty, integrity, and fairness in areas such as corporate governance, employee relations, and interactions with clients and communities. ",
    totalComplaints: "The total number of formal grievances or complaints received from employees, customers, or stakeholders about the company's operations or behavior during the reporting period.",
    resolvedComplaints: "The total number of formal grievances or complaints from employees, customers, or stakeholders that were resolved regarding the company's operations or behavior during the reporting period.",
    boardMembers: "The total number of individuals serving on the company's board of directors, who are responsible for overseeing the corporate management and making key business decisions.",
    csrSpending: "The total amount of money the company has spent on Corporate Social Responsibility (CSR) activities, which may include environmental efforts, philanthropy, ethical labor practices, and community engagement programs during the reporting period.",
  };

  const dynamicUnitMapping = unitsData?.data.reduce((acc, unit) => {
    acc[unit.id] = unit.name;
    return acc;
  }, {});

  const isMessages = true;

  const [modalState, setModalState] = useState({
    visible: false,
    top: 0,
    left: 0,
    symbol: null, // Track which symbol triggered the modal
    code: "",
    kpiName: "",
    alreadyTagged: [],
  });

  const handleAssignClick = (event, symbol, alreadyTagged) => {
    const buttonRect = event.target.getBoundingClientRect(); // Get button position
    const maxModalHeight = 550; // Max height of the modal

    const viewportHeight = window.innerHeight; // Total available viewport height
    const spaceBelow = viewportHeight - buttonRect.bottom; // Space below the button
    const spaceAbove = buttonRect.top; // Space above the button

    // Decide whether to position the modal above or below the button
    let topPosition;
    let modalHeight; // The height of the modal

    if (spaceBelow >= maxModalHeight) {
      // Enough space below the button, place the modal below
      topPosition = buttonRect.bottom;
      modalHeight = maxModalHeight; // Max height because there's enough space below
    } else if (spaceAbove >= maxModalHeight) {
      // Not enough space below, but enough space above, place the modal above
      topPosition = buttonRect.top - maxModalHeight;
      modalHeight = maxModalHeight; // Max height because there's enough space above
    } else if (spaceBelow > spaceAbove) {
      // More space below, use available space below
      topPosition = buttonRect.bottom;
      modalHeight = spaceBelow; // Set height to available space below
    } else {
      // More space above, use available space above
      topPosition = buttonRect.top - spaceAbove;
      modalHeight = spaceAbove; // Set height to available space above
    }

    // Adjust left position, ensuring the modal fits within the viewport width
    const modalWidth = 500; // Assume modal width
    const leftPosition = Math.min(
      buttonRect.right - modalWidth,
      window.innerWidth - modalWidth
    );

    // Set modal state with dynamically calculated top, left, and height
    setModalState({
      visible: true,
      top: topPosition,
      left: leftPosition,
      symbol,
      height: modalHeight,
      code: symbol,
      kpiName: "governanceKPI",
      alreadyTagged,
    });
  };

  // Hide the modal
  const closeModal = () => {
    setModalState((prev) => ({
      ...prev,
      visible: false,
      // top: 0,
      // left: 0,
      // symbol: null,
    }));
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="new-kpi-table-container">
        <div className="new-kpi-table">
          {/* Table Header */}
          <div className="new-kpi-table-header">
            <div className="new-kpi-table-row new-kpi-table-head-row">
              {headings?.map((heading, index) => (
                <div
                  key={index}
                  className={`new-kpi-table-cell header-cell ${index === 0 || index > 2 ? "centered" : ""
                    }`}
                >
                  <div className="new-kpi-table-header-content">{heading}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Table Body */}
          {!loading && !unitsLoading ? (
            <div className="new-kpi-table-body">
              {Object?.keys(dataToMap)?.map((symbol) => {
                if (symbolsToNotMap.includes(symbol)) return null;

                // Check if any value is missing for the symbol
                const isSymbolMissingValue = Object.keys(
                  dataToMap[symbol]
                ).some(
                  (dataKey) =>
                    !dataPointsToNotMap.includes(dataKey) &&
                    (dataToMap[symbol][dataKey]?.value === undefined ||
                      dataToMap[symbol][dataKey]?.value === "" ||
                      dataToMap[symbol][dataKey]?.value === null)
                );

                // Determine background color for the symbol cell
                const symbolColor = !isAiGenerated
                  ? "inherit" // or "none" if you want no color applied, or any other fallback color
                  : isSymbolMissingValue
                    ? "" //#ffeded
                    : "#f6fffa";

                if (!dataToMap[symbol]?.assign) {
                  dataToMap[symbol] = {
                    ...dataToMap[symbol],
                    assign: { assignMembers: [] },
                  };
                }

                const assignMembers =
                  dataToMap[symbol]?.assign?.assignMembers || [];

                // Filter `teamMembers` to get only the members with IDs in `assignMembers`
                const assignedUsers =
                  allUsers?.filter((member) =>
                    assignMembers.includes(member.id)
                  ) || [];

                return (
                  <div
                    key={symbol}
                    className="new-kpi-table-row new-kpi-table-body-row"
                  >
                    {/* Symbol */}
                    <Tooltip
                      followCursor={true}
                      title={
                        <span style={{ fontSize: "12px", color: "black" }}>
                          {tooltipMapping[symbol] || "No description available"}
                        </span>
                      }
                      placement="top"
                      arrow
                      PopperProps={{
                        modifiers: [
                          {
                            name: "arrow",
                            options: {
                              padding: 5, // Offset the arrow a bit
                            },
                          },
                        ],
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "#E6F5F1", // Tooltip background color
                            color: "black", // Text color
                            fontSize: "12px", // Font size
                            padding: "8px", // Optional: Adjust padding
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#E6F5F1", // Arrow color (same as background)
                          },
                        },
                      }}
                    >
                      <div
                        className="new-kpi-table-cell centered"
                        style={{
                          backgroundColor: symbolColor,
                          cursor: "pointer",
                        }}
                      >
                        <div className="new-kpi-table-cell-content">
                          {symbol}
                        </div>
                      </div>
                    </Tooltip>

                    {/* Data Points */}
                    <div className="new-kpi-table-cell">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific data point value is missing
                        const isDataPointMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null;

                        // Determine the background color for this specific data point
                        const cellColor = isAiGenerated
                          ? isDataPointMissing
                            ? "" // Red if missing
                            : "#f6fffa" // No color if present
                          : "";

                        return (
                          <Tooltip
                            followCursor={true}
                            title={
                              <span style={{ fontSize: "12px", color: "black" }}>
                                {dataPointTooltipMapping[dataKey] || "No description available"}
                              </span>
                            }
                            placement="top"
                            arrow
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "arrow",
                                  options: {
                                    padding: 5,
                                  },
                                },
                              ],
                            }}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#E6F5F1",
                                  color: "black",
                                  fontSize: "12px",
                                  padding: "8px",
                                },
                              },
                              arrow: {
                                sx: {
                                  color: "#E6F5F1",
                                },
                              },
                            }}
                          >
                            <div
                              key={idx}
                              className="new-kpi-table-cell-content data"
                              style={{ backgroundColor: cellColor }} // Apply background color to individual data points
                            >
                              {keyMapping[dataKey] || dataKey}
                            </div>
                          </Tooltip>
                        );
                      })}
                    </div>

                    {/* Units */}
                    <div className="new-kpi-table-cell">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific data point value is missing for unit cell
                        const isDataPointMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null;

                        // Determine the background color for this specific unit cell
                        const cellColor = isAiGenerated
                          ? isDataPointMissing
                            ? "" // Red if missing
                            : "#f6fffa" // No color if present
                          : "";

                        const unit =
                          dynamicUnitMapping[dataToMap[symbol][dataKey]?.unit];
                        const showUnit = unit === "0/1" ? "Yes/No" : unit;

                        return (
                          <div
                            key={idx}
                            className="new-kpi-table-cell-content data"
                            style={{ backgroundColor: cellColor }}
                          >
                            {showUnit || "N/A"}
                          </div>
                        );
                      })}
                    </div>

                    {/* Values */}
                    <div className="new-kpi-table-cell centered">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific value is missing for value cell
                        const isValueMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null;

                        // Determine the background color for this specific value cell
                        const cellColor = isAiGenerated
                          ? isValueMissing
                            ? "" // Red if missing
                            : "#f6fffa" // No color if present
                          : "";

                        const unit =
                          dynamicUnitMapping[dataToMap[symbol][dataKey].unit];
                        const value = dataToMap[symbol][dataKey].value;

                        if (unit === "0/1") {
                          return (
                            <div
                              className="new-kpi-table-cell-content input-wrapper"
                              key={idx}
                              style={{ backgroundColor: cellColor }} // Apply background color to Yes/No buttons
                            >
                              <button
                                className={`yes-no-btn yes-btn ${value === true ? "yes-btn-selected" : ""
                                  }`}
                                onClick={() =>
                                  user?.role === "member" &&
                                  handleInputChange(symbol, dataKey, "true")
                                }
                              >
                                Yes
                              </button>
                              <button
                                className={`yes-no-btn no-btn ${value === false ? "no-btn-selected" : ""
                                  }`}
                                onClick={() =>
                                  user?.role === "member" &&
                                  handleInputChange(symbol, dataKey, "false")
                                }
                              >
                                No
                              </button>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              key={idx}
                              className="new-kpi-table-cell-content input-wrapper"
                              style={{ backgroundColor: cellColor }} // Apply background color to individual input cells
                            >
                              <input
                                disabled={user.role === "viewer"}
                                type="number"
                                value={
                                  dataToMap[symbol][dataKey]?.value !==
                                    undefined &&
                                    dataToMap[symbol][dataKey]?.value !== null
                                    ? dataToMap[symbol][dataKey].value
                                    : ""
                                }
                                className="input-field"
                                placeholder="Enter value"
                                onChange={(e) =>
                                  handleInputChange(
                                    symbol,
                                    dataKey,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          );
                        }
                      })}
                    </div>

                    {/* Messages */}
                    <div className="new-kpi-table-cell centered">
                      <div className="new-kpi-table-cell-content">
                        {dataToMap[symbol]?.assign?.assignMembers?.length >
                          0 ? (
                          <div className="messages-wrapper">
                            <div className="profile-images-container">
                              {/* Show the first 3 profile images */}
                              {assignedUsers?.slice(0, 2)?.map((img) => (
                                <img
                                  key={`${constant.IMG_URL}/${img.profileImage}`}
                                  src={
                                    `${constant.IMG_URL}/${img.profileImage}` ??
                                    dummyUser
                                  }
                                  alt="Messaged"
                                  height={28}
                                  width={28}
                                />
                              ))}

                              {/* Show the count of remaining users if more than 3 */}
                              {assignedUsers.length > 2 && (
                                <div className="remaining-count">
                                  +{assignedUsers.length - 2}
                                </div>
                              )}
                            </div>
                            <div
                              onClick={(event) =>
                                handleAssignClick(event, symbol, assignMembers)
                              }
                              style={{ cursor: "pointer" }}
                              className="notifications-div"
                            >
                              <img
                                src={messages}
                                alt="Messaged"
                                height={24}
                                width={24}
                              />
                              {/* <span className="unread">2 Unread</span> */}
                               <span className="unread">{dataToMap[symbol]?.unReadMessage} Unread</span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>

      <AssignModal closeModal={closeModal} modalState={modalState} />
    </div>
  );
};

export default GovernanceKpi;
